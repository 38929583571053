import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, FeaturedStat, PrimaryClaim, KMCurve, SecondaryCallout, ComparisonTable, NCCNcallout, PatientProfile, ReferencesBlock } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import NSCLC_1L_COMBO_NSQ from '../../components/StudyDesign/__study-design/nsclc-1l-combo-nsq'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-189 - Clinical Trial Results | HCP`,
    keywords: `keynote-189`,
    description: `Health care professionals may find clinical trial results for first-line combination therapy with KEYTRUDA® (pembrolizumab) for nonsquamous mNSCLC in KEYNOTE-189.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-nonsquamous-first-line-combination-therapy/","@type":"ApprovedIndication","name":" KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, in combination with pemetrexed and platinum chemotherapy, is indicated for the first-line treatment of patients with metastatic nonsquamous non⁠–⁠small cell lung cancer (NSCLC), with no epidermal growth factor receptor (EGFR) or anaplastic lymphoma kinase (ALK) genomic tumor aberrations. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-nonsquamous-first-line-combination-therapy/","@type":"MedicalAudience","audienceType":"US Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-nonsquamous-first-line-combination-therapy/","@type":"MedicalTrial","name":"Clinical Findings From KEYNOTE⁠-⁠189","description":"Study Design for KEYNOTE⁠-⁠189","trialDesign":"Phase 3, randomized, multicenter, double-blind, active-controlled trial in systemic therapy–naïve patients with metastatic nonsquamous NSCLC, regardless of PD⁠-⁠L1 tumor expression status and with no EGFR or ALK genomic tumor aberrations"}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/nsclc-nonsquamous-first-line-combination-therapy/","@type":"MedicalGuideline","guideline":"Pembrolizumab (KEYTRUDA), in combination with pemetrexed and platinum chemotherapy, is a recommended (category 1 and preferred) first-line treatment for patients with metastatic nonsquamous non–small cell lung cancer (NSCLC), with no epidermal growth factor receptor (EGFR) or anaplastic lymphoma kinase (ALK) genomic tumor aberrations, regardless of PD⁠-⁠L1 expression.","recognizingAuthority":"NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)"}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 4;
const jobCode = jobCodes[4].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: '1L Combo Therapy – Squamous', url: '/efficacy/nsclc-squamous-first-line-combination-therapy/' },
                { text: '1L Monotherapy – Nonsquamous & Squamous', url: '/efficacy/nsclc-first-line-monotherapy/' },
                { text: '2L Monotherapy – Nonsquamous & Squamous', url: '/efficacy/nsclc-second-line-monotherapy/' },
                { text: 'Neoadjuvant Combo Therapy Followed by Adjuvant Monotherapy After Surgery for Resectable (Tumors ≥4 cm or Node Positive) NSCLC', url: '/efficacy/nsclc-perioperative-regimen/' },
                { text: 'Adjuvant Therapy for Stage IB (T2a ≥4 cm), Stage II, or Stage IIIA NSCLC', url: '/efficacy/nsclc-adjuvant-therapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/nsclc-nonsquamous-first-line-combination-therapy/' },
                { text: 'Dosing', url: '/dosing/options/' },
                { text: 'PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' }
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings From KEYNOTE&#8288;-&#8288;189' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;189' }, 
    { label: 'NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)' }, 
    { label: 'Appropriate Patients' }
]

const osSurvivalComparisonTableFootnotes = [
    {
        label: 'a.',
        text: 'HR based on the stratified Cox proportional hazard model; <i>P</i> value based on a stratified log-rank test.'
    }
]

const featuredStatFootnotes = [
    {
        label: 'b.',
        text: 'HR based on the stratified Cox proportional hazard model; <i>P</i> value based on a stratified log-rank test.'
    }
]

const overallSurvivalWithOrWithoutPDLExprFootnotes = [
    {
        label: 'c.',
        text: 'Some patients received >1 subsequent anti&#8288;–&#8288;PD&#8288;-&#8288;(L)1 therapy.'
    },
]

const nonexpressersOSKMCurveFootnotes = [
    {
        label: 'e.',
        text: 'HR based on Cox regression model with treatment as a covariate stratified by PD&#8288;-&#8288;L1 status (≥1% vs <1%), platinum chemotherapy (cisplatin vs carboplatin), and smoking status (never vs former/current).'
    }
]

const expressersOSKMCurveFootnotes = [
    {
        label: 'd.',
        text: 'HR based on Cox regression model with treatment as a covariate stratified by PD&#8288;-&#8288;L1 status (≥1% vs <1%), platinum chemotherapy (cisplatin vs carboplatin), and smoking status (never vs former/current).'
    },
]

const initialAnalysisPFSComparisonTableFootnotes = [
    {
        label: 'd.',
        text: 'HR based on the stratified Cox proportional hazard model; <i>P</i> value based on a stratified log-rank test.'
    }
]

const initialAnalysisORRComparisonTableFootnotes = [
    {
        label: 'e.',
        text: 'Response: Best objective response as confirmed complete response or partial response.'
    },
    {
        label: 'f.',
        text: 'Based on Miettinen and Nurminen method stratified by PD&#8288;-&#8288;L1 status, platinum chemotherapy, and smoking status.'
    },
]

const NCCNcalloutFootnotes = [
    {
        label: "g.",
        text: "See NCCN Guidelines® for detailed recommendations, including combination regimens, in patients with no targetable genomic tumor aberrations."
    },
    {
        label: "h.",
        text: "See NCCN Guidelines® for recommendations specific to genetic alterations, including <i>EGFR</i>, <i>ALK</i>, <i>ROS1</i>, <i>BRAF</i>, <i>MET</i>, <i>KRAS</i>, <i>NTRK1/2/3</i>, <i>RET</i>, and <i>ERBB2 (HER2)</i>."
    },
    {
        label: "i.",
        text: "Pembrolizumab/(carboplatin or cisplatin)/pemetrexed is recommended (category 1 and preferred) as first-line therapy for certain patients with metastatic nonsquamous NSCLC."
    },
]
const NCCNcalloutData = {
    title: 'Category 1 and PREFERRED recommendation regardless of PD&#8288;-&#8288;L1 expression<sup>4,g-i</sup>',
    bodyCopy: 'Pembrolizumab (KEYTRUDA), in combination with pemetrexed and platinum chemotherapy, is a recommended (category 1 and preferred) first-line treatment for patients with metastatic nonsquamous non–small cell lung cancer (NSCLC), with no epidermal growth factor receptor (EGFR) or anaplastic lymphoma kinase (ALK) genomic tumor aberrations, regardless of PD&#8288;-&#8288;L1 expression.',
    showBadge: false,
    alt: ""
}

const NCCNcalloutDefinitions = `<p>According to NCCN, preferred intervention = Intervention that is based on superior efficacy, safety, and evidence, and, when appropriate, affordability; Category 1 = Based upon high-level evidence (≥1 randomized phase 3 trials or high-quality, robust meta-analyses), there is uniform NCCN consensus (≥85% support of the Panel) that the intervention is appropriate.<sup>4</sup></p>
                                <p>NCCN makes no warranties of any kind whatsoever regarding their content, use, or application and disclaims any responsibility for their application or use in any way.</p>
                                <p>BRAF = B-Raf proto-oncogene, serine/threonine kinase; ERBB2 = Erb-B2 receptor tyrosine kinase 2; HER2 = human epidermal growth factor receptor 2; KRAS= Kirsten rat sarcoma viral oncogene homolog; MET = mesenchymal-epithelial transition; NTRK1/2/3 = neurotrophic tyrosine receptor kinase; RET = rearranged during transfection; ROS1 = ROS proto-oncogene 1, receptor tyrosine kinase.</p>`

const pageReferences = [
    {
        label: "1.",
        text: "Gandhi L, Rodríguez-Abreu D, Gadgeel S, et al; for the KEYNOTE&#8288;-&#8288;189 investigators. Pembrolizumab plus chemotherapy in metastatic non–small-cell lung cancer. <i>N Engl J Med.</i> 2018;378(22):2078–2092. "
    },
    {
        label: "2.",
        text: "Rodríguez-Abreu D, Powell SF, Hochmair MJ, et al. Pemetrexed plus platinum with or without pembrolizumab in patients with previously untreated metastatic nonsquamous NSCLC protocol-specified final analysis from KEYNOTE-189. <i>Ann Oncol.</i> 2021;32(7):881-895."
    },
    {
        label: "3.",
        text: "Garassino MC, Gadgeel S, Speranza G, et al. Pembrolizumab plus pemetrexed and platinum in nonsquamous non-small-cell lung cancer: 5-year outcomes from the phase 3 KEYNOTE-189 study. <i>J Clin Oncol</i>. 2023;41(11):1992-1998. doi:10.1200/JCO.22.01989"
    },
    {
        label: "4.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Non-Small Cell Lung Cancer V.11.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed October 15, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org."
    }
]

const NCCNcalloutReferences = [
    {
        label: "4.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Non-Small Cell Lung Cancer V.11.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed October 15, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org."    },
]

const patientProfileMurray = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/murray.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Murray",
    diagnosis: "Nonsquamous mNSCLC (adenocarcinoma)",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'EGFR/ALK',
            value: 'Negative',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: '<1%',
            emphasize: false,
        },
        {
            key: 'Metastases',
            value: 'Liver',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '65',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
        {
            key: 'Physical Activity',
            value: 'Low',
        },
        {
            key: 'Smoking History',
            value: 'Former smoker; 30 pack-year history',
        },
        {
            key: 'Family History',
            value: 'No family history of cancer',
        },
    ],
}

const patientProfileKeith = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-keith@2x.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Keith",
    diagnosis: "Nonsquamous mNSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'EGFR/ALK',
            value: 'Negative',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '0',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: 'Unknown',
            emphasize: false,
        },
        {
            key: 'Metastases',
            value: 'Liver',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '60',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
        {
            key: 'Physical Activity',
            value: 'High',
        },
        {
            key: 'Smoking History',
            value: 'Never smoked',
        },
        {
            key: 'Family History',
            value: 'Information unknown',
        },
    ],
}

const patientProfileScott = {
    image: () => (
        <StaticImage layout="fixed" src="../../assets/patient-scott@2x.png" placeholder="TRACED_SVG" alt="" width={100} height={100} />
    ),
    name: "Scott",
    diagnosis: "Nonsquamous mNSCLC",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: 'No prior history of therapy',
            emphasize: false,
        },
        {
            key: 'EGFR/ALK',
            value: 'Negative',
            emphasize: false,
        },
        {
            key: 'ECOG Status',
            value: '1',
            emphasize: false,
        },
        {
            key: 'PD&#8288;-&#8288;L1 Expression',
            value: '10%',
            emphasize: false,
        },
        {
            key: 'Metastases',
            value: 'Brain',
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '62',
        },
        {
            key: 'Gender',
            value: 'Male',
        },
        {
            key: 'Physical Activity',
            value: 'Moderate',
        },
        {
            key: 'Smoking History',
            value: '20-pack-year history, quit 13 years ago',
        },
        {
            key: 'Family History',
            value: 'No family history of cancer',
        },
    ],
}

const patientProfileDefinitions = "mNSCLC = metastatic NSCLC; ECOG = Eastern Cooperative Oncology Group."

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">Durable Overall Survival With KEYTRUDA as First-line Combination Therapy With Plat/pem vs Plat/pem Alone</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <PageSubSection title="Initial Analysis (Median Follow-up Time: 10.5 Months)<sup>1</sup>">
                                <SecondaryCallout 
                                    title="Superior overall survival with KEYTRUDA + plat/pem vs plat/pem alone" 
                                    alt=""
                                />
                                <ComparisonTable
                                    title="Overall Survival (OS)"
                                    footnotes={osSurvivalComparisonTableFootnotes}
                                    definitions="Plat/pem = (cisplatin or carboplatin) and pemetrexed; HR = hazard ratio; CI = confidence interval; NR = not reached."
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA + plat/pem (n=410)',
                                                },
                                                {
                                                    legendTitle: 'Plat/pem (n=206)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR=0.49 (95% CI, 0.38–0.64; <i>P</i><0.0001)<sup>a</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '127',
                                                    dataSubPoint: '(31%)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '108',
                                                    dataSubPoint: '(52%)'
                                                },

                                            ],
                                            [
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: 'Not reached',
                                                    dataSubPoint: '(95% CI, NR–NR)'
                                                },
                                                {
                                                    label: 'Median OS',
                                                    dataPoint: '11.3 months',
                                                    dataSubPoint: '(95% CI, 8.7–15.1)'
                                                },

                                            ],
                                        ]
                                    } />
                                <FeaturedStat
                                    statistic="51%"
                                    title="Reduction in the risk of death with KEYTRUDA + plat/pem vs plat/pem alone<div style='margin-top: 10px;'><b>HR=0.49 (95% CI, 0.38–0.64; <i>P</i><0.0001)<sup>b</sup></b></div>"
                                    footnotes={featuredStatFootnotes}
                                />

                                <PageSubSection title="Protocol-Specified Final OS Analysis (Median Follow-up Time: 31.0 Months)<sup>2</sup>">
                                    <ComparisonTable
                                        title="Overall Survival (OS)"
                                        data={
                                            [
                                                [
                                                    {
                                                        legendTitle: 'KEYTRUDA + plat/pem (n=410)',
                                                    },
                                                    {
                                                        legendTitle: 'Plat/pem (n=206)',
                                                    },
                                                ],
                                                [
                                                    {
                                                        hrCiP: 'HR=0.56 (95% CI, 0.46–0.69)',
                                                        colspan: 2,
                                                        centerText: true,
                                                    },
                                                ],
                                                [
                                                    {
                                                        label: 'Median OS',
                                                        dataPoint: '22.0 months',
                                                        dataSubPoint: '(95% CI, 19.5–24.5)'
                                                    },
                                                    {
                                                        label: 'Median OS',
                                                        dataPoint: '10.6 months',
                                                        dataSubPoint: '(95% CI, 8.7–13.6)'
                                                    },

                                                ]
                                            ]
                                        } />
                                </PageSubSection>

                                <PageSubSection title="Exploratory Analysis (Median Follow-up Time: 64.6 Months)<sup>3</sup>">
                                    <KMCurve
                                        title="Overall Survival (OS), Regardless of PD&#8288;-&#8288;L1 Expression<sup>3</sup>"
                                        limitation="<p><b>LIMITATION:</b> This post-hoc analysis (median follow-up time: 64.6 months) in KEYNOTE&#8288;-&#8288;189 was exploratory in nature and occurred after the protocol-specified final analysis. No formal statistical testing was planned for this updated analysis and, therefore, no statistical conclusions can be drawn. Trial participants in either study arm could receive subsequent anti-cancer therapy.</p>
                                        <p><b>CROSSOVER RATE:</b> 57.3% (118/206) of patients crossed over from plat/pem to anti&#8288;–&#8288;PD&#8288;-&#8288;(L)1 therapy on (n=84) or off (n=34) study.<sup>c</sup></p>
                                        "
                                        hr="HR=0.60 (95% CI, 0.50–0.72)"
                                        footnotes={overallSurvivalWithOrWithoutPDLExprFootnotes}
                                        definitions="PD-1 = programmed death receptor-1; PD&#8288;-&#8288;L1 = programmed death ligand 1."
                                        image={() => <StaticImage src="../../assets/NSCLC-1L-Combo-NSQ-KM-Exploratory-V2.png"
                                            placeholder="blurred"
                                            alt="Kaplan-Meier Curve Showing Overall Survival (OS) in Patients Regardless of PD-L1 Expression in KEYNOTE-189" />}
                                    />

                                    <ComparisonTable
                                        title="Overall Survival (OS)<sup>3</sup>"
                                        data={
                                            [
                                                [
                                                    {
                                                        legendTitle: 'KEYTRUDA + plat/pem (n=410)',
                                                    },
                                                    {
                                                        legendTitle: 'Plat/pem (n=206)',
                                                    },
                                                ],
                                                [
                                                    {
                                                        hrCiP: 'HR=0.60 (95% CI, 0.50–0.72)',
                                                        colspan: 2,
                                                        centerText: true,
                                                    },
                                                ],
                                                [
                                                    {
                                                        label: 'Events Observed',
                                                        dataPoint: '329',
                                                        dataSubPoint: '(80%)',
                                                    },
                                                    {
                                                        label: 'Events Observed',
                                                        dataPoint: '183',
                                                        dataSubPoint: '(89%)',
                                                    },

                                                ],
                                                [
                                                    {
                                                        label: 'Median OS',
                                                        dataPoint: '22.0 months',
                                                        dataSubPoint: '(95% CI, 19.5–24.5)'
                                                    },
                                                    {
                                                        label: 'Median OS',
                                                        dataPoint: '10.6 months',
                                                        dataSubPoint: '(95% CI, 8.7–13.6)'
                                                    },

                                                ]
                                            ]
                                        } />
                                </PageSubSection>

                                <PageSubSection title="Exploratory Analysis (Median Follow-up Time: 64.6 Months): Overall Survival in PD&#8288;-&#8288;L1 Subgroups, Including Patients With or Without PD-L1 Expression<sup>3</sup>">
                                    <KMCurve
                                        title="Overall Survival (OS) in Patients With PD&#8288;-&#8288;L1 Expression (TPS&nbsp;≥1%)<sup>3</sup>"
                                        limitation="<p><b>LIMITATION:</b>  KEYNOTE&#8288;-&#8288;189 was not powered to detect differences in the treatment effect in this subgroup; therefore, results from this exploratory analysis should be interpreted with caution because of the modest patient numbers and potential imbalances in baseline characteristics within the subgroup.</p>
                                        <p><b>STUDY CROSSOVER:</b> Crossover to open-label KEYTRUDA was allowed in KEYNOTE&#8288;-&#8288;189.</p>
                                        "
                                        hr="HR=0.66 (95% CI, 0.52–0.84)"
                                        image={() => <StaticImage src="../../assets/NSCLC-1L-Combo-NSQ-KM-with-PDL1-V4.png"
                                            placeholder="blurred"
                                            alt="Kaplan-Meier Curve Showing Overall Survival (OS) in Patients With PD-L1 Expression (TPS ≥1%) in KEYNOTE-189" />}
                                    />

                                    <KMCurve
                                        title="Overall Survival (OS) in Patients Without PD&#8288;-&#8288;L1 Expression (TPS&nbsp;<1%)<sup>3</sup>"
                                        limitation="<p><b>LIMITATION:</b> KEYNOTE&#8288;-&#8288;189 was not powered to detect differences in the treatment effect in this subgroup; therefore, results from this exploratory analysis should be interpreted with caution because of the modest patient numbers and potential imbalances in baseline characteristics within the subgroup.</p>
                                        <p><b>STUDY CROSSOVER:</b> Crossover to open-label KEYTRUDA was allowed in KEYNOTE&#8288;-&#8288;189.</p>
                                        "
                                        hr="HR=0.55 (95% CI, 0.39–0.76)"
                                        definitions='TPS = tumor proportion score.'
                                        image={() => <StaticImage src="../../assets/NSCLC-1L-Combo-NSQ-KM-without-PDL1-V5.png"
                                            placeholder="blurred"
                                            alt="Kaplan-Meier Curve Showing Overall Survival (OS) in Patients Without PD-L1 Expression (TPS <1%) in KEYNOTE-189" />}
                                    />
                                </PageSubSection>

                                <PageSubSection title="Initial Analysis: PFS and ORR (Median Follow-up Time: 10.5 Months)<sup>1</sup>">
                                    <SecondaryCallout 
                                        title="KEYTRUDA + plat/pem demonstrated superior PFS and ORR vs plat/pem alone in an initial analysis of KEYNOTE&#8288;-&#8288;189" 
                                        alt=""    
                                    />
                                </PageSubSection>
                                <ComparisonTable
                                    title="Progression-Free Survival (PFS)"
                                    additionalInformation={[
                                        {
                                            title: 'Additional Information',
                                            text: `
                                                <p>48% reduction in the risk of disease progression or death with KEYTRUDA + plat/pem vs plat/pem alone (HR=0.52; 95% CI, 0.43–0.64; <i>P</i><0.0001)<sup>d</sup></p>`
                                        },

                                    ]}
                                    footnotes={initialAnalysisPFSComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA + plat/pem (n=410)',
                                                },
                                                {
                                                    legendTitle: 'Plat/pem (n=206)',
                                                },
                                            ],
                                            [
                                                {
                                                    hrCiP: 'HR=0.52 (95% CI, 0.43–0.64; <i>P</i><0.0001)<sup>d</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '245',
                                                    dataSubPoint: '(60%)'
                                                },
                                                {
                                                    label: 'Events Observed',
                                                    dataPoint: '166',
                                                    dataSubPoint: '(81%)'
                                                },

                                            ],
                                            [
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '8.8 months',
                                                    dataSubPoint: '(95% CI, 7.6–9.2)'
                                                },
                                                {
                                                    label: 'Median PFS',
                                                    dataPoint: '4.9 months',
                                                    dataSubPoint: '(95% CI, 4.7–5.5)'
                                                },

                                            ]
                                        ]
                                    } />

                                <ComparisonTable
                                    title="Objective Response Rate (ORR)<sup>e</sup>: More Than Doubled With KEYTRUDA + Plat/Pem vs Plat/Pem Alone"
                                    footnotes={initialAnalysisORRComparisonTableFootnotes}
                                    data={
                                        [
                                            [
                                                {
                                                    legendTitle: 'KEYTRUDA + plat/pem (n=410)',
                                                },
                                                {
                                                    legendTitle: 'Plat/pem (n=206)',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'ORR',
                                                    dataPoint: '48%',
                                                    dataSubPoint: '(95% CI, 43–53)'
                                                },
                                                {
                                                    label: 'ORR',
                                                    dataPoint: '19%',
                                                    dataSubPoint: '(95% CI, 14–25)'
                                                },

                                            ],
                                            [
                                                {
                                                    label: 'Complete Response',
                                                    dataPoint: '0.5%',
                                                },
                                                {
                                                    label: 'Complete Response',
                                                    dataPoint: '0.5%',
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Partial Response',
                                                    dataPoint: '47%',
                                                },
                                                {
                                                    label: 'Partial Response',
                                                    dataPoint: '18%',
                                                },
                                            ],
                                            [
                                                {
                                                    dataPoint: '<i>P</i><0.0001<sup>f</sup>',
                                                    colspan: 2,
                                                    centerText: true,
                                                },
                                            ],
                                            [
                                                {
                                                    label: 'Median DOR',
                                                    dataPoint: '11.2 months',
                                                    dataSubPoint: '(range: 1.1+ – 18.0+ months)'
                                                },
                                                {
                                                    label: 'Median DOR',
                                                    dataPoint: '7.8 months',
                                                    dataSubPoint: '(range: 2.1+ – 16.4+ months)'
                                                },
                                            ]
                                        ]
                                    } />
                            </PageSubSection>

                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <NSCLC_1L_COMBO_NSQ />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[2].label}>
                            <NCCNcallout
                                data={NCCNcalloutData}
                                footnotes={NCCNcalloutFootnotes}
                                definitions={NCCNcalloutDefinitions}
                                references={NCCNcalloutReferences}
                            />
                        </PageSection>

                        <PageSection bgColor="cloud" title={anchorLinkModelData[3].label}>
                            <PageSubSection title="Hypothetical Patients Based on Those Studied in KEYNOTE&#8288;-&#8288;189">
                                <PatientProfile {...patientProfileMurray} definitions={patientProfileDefinitions} />
                                <PatientProfile {...patientProfileKeith} definitions={patientProfileDefinitions} />
                                <PatientProfile {...patientProfileScott} definitions={patientProfileDefinitions} />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
